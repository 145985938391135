
import { companyService } from '../services';
import baseMethods from './base';

const initialState = 
                    { companyList: {data: null,status:{ loading: false,ready: false }},
                      companyFull: {
                          data: {name:"",email:"",web:"",tel1:"",tel2:"",fax:"",address_fk:-1,bank_account_fk:-1,uid:""},status:{ loading: false,ready: false },
                          dataOld:""
                                    },
                      companyMember: {data: null,status:{ loading: false,ready: false }},
                      companiesOrderPossible: {data: null,status:{ loading: false,ready: false }}
                };

export const company = {
    namespaced: true,
    state: initialState,
    actions: {
        getCompanyList({ commit } )
        {
            commit('getCompanyListRequest' );
            return new Promise((resolve, reject) => {
                
                companyService.getCompanyList().then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getCompanyListSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getCompanyListFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getCompaniesOrderPossible({ commit } )
        {
            commit('getCompaniesOrderPossibleRequest' );
            return new Promise((resolve, reject) => {
                
                companyService.getCompaniesOrderPossible().then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getCompaniesOrderPossibleSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getCompaniesOrderPossibleFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getCompanyMember({ commit },id )
        {
            commit('getCompanyMemberRequest' );
            return new Promise((resolve, reject) => {
                
                companyService.getCompanyMember(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getCompanyMemberSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getCompanyMemberFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        }, 
        deleteCompanyMember({ commit },data )
        {
            commit('deleteCompanyMemberRequest' );
            return new Promise((resolve, reject) => {
                
                companyService.deleteCompanyMember(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteCompanyMemberSuccess',data.guid);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteCompanyMemberFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },             
        getCompanyFullData({commit},id){
            commit('getCompanyRequest' );
            return new Promise((resolve, reject) => {
                
                companyService.getCompany(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getCompanySuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getCompanyFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteCompany({ commit }, id)
        {
            commit('deleteCompanyRequest' );
            return new Promise((resolve, reject) => {
                
                companyService.deleteCompany(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteCompanySuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteCompanyFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        newCompany({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                commit('insertCompanyRequest' );
                companyService.insertCompany(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('insertCompanySuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('insertCompanyFailure');
                            reject(response.statusText);
                        }
                    }
                );
            })
        },
        saveCompany({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                    commit('updateCompanyRequest' );
                    companyService.updateCompany(data).then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateCompanySuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateCompanyFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                
            })
        },
        updateCompanyMember({ commit }, data)
        {
            return new Promise((resolve, reject) => {

                    commit('updateCompanyMemberRequest' );
                    companyService.updateCompanyMember(data).then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateCompanyMemberSuccess');
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateCompanyMemberFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                
            })
        },      
        clearCompanyFullData({ commit })
        {
            commit('clearCompanyFullData');
        },
        updateCompanyFullDataCompanyName({ commit }, name)
        {
            commit('updateFullDataName',name);
        },
        updateCompanyFullDataEmail({ commit }, email)
        {
            commit('updateFullDataEmail',email);
        },
        updateCompanyFullDataTel({ commit }, tel)
        {
            commit('updateFullDataTel',tel);
        },
        updateCompanyFullDataTel2({ commit }, tel2)
        {
            commit('updateFullDataTel2',tel2);
        },
        updateCompanyFullDataFax({ commit }, fax)
        {
            commit('updateFullDataFax',fax);
        },
        updateCompanyFullDataAddressFk({ commit }, fk)
        {
            commit('updateFullDataAddressFk',fk);
        },
        updateCompanyFullDataWeb({ commit }, web)
        {
            commit('updateFullDataWeb',web);
        },
        updateCompanyFullDataUid({ commit }, uid)
        {
            commit('updateFullDataUid',uid);
        },
        updateCompanyFullDataBankAccountFk({ commit }, fk)
        {
            commit('updateFullDataBankAccountFk',fk);
        },
        updateCompanyFullDataSlogan({ commit }, slogan)
        {
            commit('updateFullDataSlogan',slogan);
        },
        updateCompanyFullDataAddressHeader({ commit }, addressHeader)
        {
            commit('updateFullDataAddressHeader',addressHeader);
        },
        updateCompanyFullDataVanue({ commit }, vanue)
        {
            commit('updateFullDataVanue',vanue);
        },
        updateCompanyFullDataDvr({ commit }, dvr)
        {
            commit('updateFullDataDvr',dvr);
        },
        updateCompanyFullDataFn({ commit }, fn)
        {
            commit('updateFullDataFn',fn);
        },
        updateCompanyFullDataAra({ commit }, ara)
        {
            commit('updateFullDataAra',ara);
        },
        updateCompanyFullDataEra({ commit }, era)
        {
            commit('updateFullDataEra',era);
        },
        updateCompanyFullDataEori({ commit }, eori)
        {
            commit('updateFullDataEori',eori);
        },
        updateCompanyFullDataFinYear({ commit }, date)
        {
            commit('updateFullDataFinYear',date);
        },

    },
    mutations: {
        clearCompanyFullData(state){
            state.companyFull.data.name = "";
            state.companyFull.data.email = "";
            state.companyFull.data.web = "";
            state.companyFull.data.tel1 = "";
            state.companyFull.data.tel2 = "";
            state.companyFull.data.fax = "";
            state.companyFull.data.uid = "";
            state.companyFull.data.id = -1;
            state.companyFull.data.address_fk = -1;
            state.companyFull.data.bank_account_fk = -1;
            state.companyFull.data.logo_filename = "";
            state.companyFull.data.slogan = "";
            state.companyFull.data.addressHeader = "";
            state.companyFull.data.vanue = "";
            state.companyFull.data.dvr = "";
            state.companyFull.data.fn = "";
            state.companyFull.data.ara = "";
            state.companyFull.data.era = "";
            state.companyFull.data.eori = "";
        },
        updateFullDataName(state,name)
        {
            state.companyFull.data.name = name;
        },
        updateFullDataEmail(state,email)
        {
            state.companyFull.data.email = email;
        },  
        updateFullDataTel(state,tel)
        {
            state.companyFull.data.tel1 = tel;
        },
        updateFullDataTel2(state,tel)
        {
            state.companyFull.data.tel2 = tel;
        },
        updateFullDataFax(state,fax)
        {
            state.companyFull.data.fax = fax;
        },
        updateFullDataAddressFk(state,fk)
        {
            state.companyFull.data.address_fk = fk;
        },
        updateFullDataWeb(state,web)
        {
            state.companyFull.data.web = web;
        },
        updateFullDataUid(state,uid)
        {
            state.companyFull.data.uid = uid;
        },
        updateFullDataBankAccountFk(state,fk)
        {
            state.companyFull.data.bank_account_fk = fk;
        },
        updateFullDataSlogan(state, slogan)
        {
            state.companyFull.data.slogan = slogan;
        },
        updateFullDataAddressHeader(state, addressHeader)
        {
            state.companyFull.data.addressHeader = addressHeader;
        },
        updateFullDataVanue(state, vanue)
        {
            state.companyFull.data.vanue = vanue;
        },
        updateFullDataDvr(state, dvr)
        {
            state.companyFull.data.dvr = dvr;
        },
        updateFullDataFn(state, fn)
        {
            state.companyFull.data.fn = fn;
        },
        updateFullDataAra(state, ara)
        {
            state.companyFull.data.ara = ara;
        },
        updateFullDataEra(state, era)
        {
            state.companyFull.data.era = era;
        },
        updateFullDataEori(state, eori)
        {
            state.companyFull.data.eori = eori;
        },
        updateFullDataFinYear(state, date)
        {
            state.companyFull.data.start_financial_year = date;
        },
        getCompanyRequest(state)
        {
            state.companyFull = {
                data: null,
                dataOld:"",
                status:{ loading: true,ready: false }
            };
        },
        getCompanySuccess(state, data)
        {
            state.companyFull = {
                data: data,
                dataOld:JSON.stringify(data),
                status:{ loading: false,ready: true }
            };
        },
        getCompanyFailure(state)
        {
            state.companyFull = {
                data: null,
                dataOld:"",
                status:{ loading: false,ready: false }
            };
        },
        deleteCompanyRequest(state)
        {
            state.companyList.status = { loading: true,ready: false };
        },
        deleteCompanySuccess(state)
        {
            state.companyList.status = { loading: false,ready: true };
        },
        deleteCompanyFailure(state)
        {
            state.companyList.status = { loading: false,ready: false };
        },                
        getCompanyListRequest(state)
        {
            state.companyList = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getCompanyListSuccess(state, data)
        {
            state.companyList = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getCompanyListFailure(state)
        {
            state.companyList = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getCompaniesOrderPossibleRequest(state)
        {
            state.companiesOrderPossible = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getCompaniesOrderPossibleSuccess(state, data)
        {
            state.companiesOrderPossible = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getCompaniesOrderPossibleFailure(state)
        {
            state.companiesOrderPossible = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        
        getCompanyMemberRequest(state)
        {
            state.companyMember = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getCompanyMemberSuccess(state, data)
        {
            state.companyMember = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getCompanyMemberFailure(state)
        {
            state.companyMember = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        updateCompanyMemberRequest(state)
        {
            state.companyMember = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },  
        updateCompanyMemberSuccess(state)
        {
            state.companyMember = {
                data:null,
                status:{ loading: false,ready: true }
            };
        },  
        updateCompanyMemberFailure(state)
        {
            state.companyMember = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        deleteCompanyMemberRequest(state)
        {
            state.companyMember.status = { loading: true,ready: false };
        },  
        deleteCompanyMemberSuccess(state,guid)
        {
            state.companyMember.status={ loading: false,ready: true };

            for(var i = 0; i < state.companyMember.data.length; i++)
            {
                if(state.companyMember.data[i].userguid == guid)
                {
                    state.companyMember.data.splice(i,1);
                    break;
                }
            }

        },  
        deleteCompanyMemberFailure(state)
        {
            state.companyMember.status = { loading: false,ready: false };
        },                         
        updateCompanyRequest(state)
        {
            state.companyList = {
                status:{ loading: true,ready: false }
            };
        },
        updateCompanySuccess(state)
        {
            state.companyList = {
                status:{ loading: false,ready: true }
            };
        },
        updateCompanyFailure(state)
        {
            state.companyList = {
                status:{ loading: false,ready: false }
            };
        },
        insertCompanyRequest(state)
        {
            state.companyList = {
                status:{ loading: true,ready: false }
            };
        },
        insertCompanySuccess(state)
        {
            state.companyList = {
                status:{ loading: false,ready: true }
            };
        },
        insertCompanyFailure(state)
        {
            state.companyList = {
                status:{ loading: false,ready: false }
            };
        }
    }
}
