import {baseAxios} from '../helper/axiosHelper';

export const accountingService = {
    getOffers,
    getOffer,
    confirmOffer,
    updateOffer,
    insertOffer,
    updateOfferMember,
    insertOfferMember,
    deleteOfferMember,
    getOfferMembers,
    updateOfferMemberAmount,
    deleteOffer,
    lockOffer,
    isOfferLocked,
    insertOfferMembers,
    reorderOfferMembers,
    createOrderForOffer,
    getOrders,
    getOrdersForUser,
    createInvoiceForOrder,
    getInvoices,
    getInvoicesForPayment,
    markInvoiceAsPayed,
    getInvoicesForPaymentById,
    getInvoicesForOrder,
    getInvoicesForNotFinishedOrders,
    finishOrder,
    addRemoveInvoiceToOrder,
    recalcOrderDiscount,
    insertDiscount,
    updateDiscount,
    deleteDiscount,
    deleteInvoice,
    getOrder,
    stornoInvoice,
    getOffersByOrder,
    getOrderStats,
    sendDocumentMail,
    getOfferForPartnerOrder,
    getOfferStats,
    getInvoiceStats
};

function getOffers(data) {

    return baseAxios.get('accounting/offers/',{params: {debitor_fk: data.debitor_fk,debitorobject_fk:data.debitorobj_fk}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getOffer(id) {

    return baseAxios.get('accounting/offer/',{params: {id: id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getOfferMembers(offer_fk) {

    return baseAxios.get('accounting/offermembers/',{params: {offer_fk: offer_fk}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateOffer(data) {

    return baseAxios.put('accounting/offer',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function confirmOffer(data) {

    return baseAxios.put('confirmation/confirm',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function lockOffer(data) {

    return baseAxios.put('accounting/offer/lock',data  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function isOfferLocked(id) {

    return baseAxios.get('accounting/offer/lock',{params: {id: id}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function insertOffer(data) {

    return baseAxios.post('accounting/offer',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateOfferMember(data) {

    return baseAxios.put('accounting/Offermember',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function updateOfferMemberAmount(data) {

    return baseAxios.put('accounting/Offermember/amount/',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertOfferMembers(data){
    return baseAxios.post('accounting/Offermembers',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertOfferMember(data) {

    return baseAxios.post('accounting/Offermember',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deleteOffer(id) {

    return baseAxios.delete('accounting/Offer',{params: {id: id}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deleteOfferMember(id) {

    return baseAxios.delete('accounting/Offermember',{params: {id: id}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function reorderOfferMembers(data) {

    return baseAxios.put('accounting/Offermember/reorder/',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function createOrderForOffer(data) {

    return baseAxios.post('accounting/OrderRequest',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getOrders(data) {

    return baseAxios.get('accounting/orders/',{params: {debitorcontact_fk: data.debitorcontact_fk}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getOrdersForUser() {

    return baseAxios.get('accounting/orders/all/')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function createInvoiceForOrder(data) {

    return baseAxios.post('accounting/InvoiceRequest',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getInvoices(data) {

    return baseAxios.get('accounting/invoices/',{params: {debitorcontact_fk: data.debitorcontact_fk}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getInvoicesForPayment(data) {

    return baseAxios.post('accounting/invoices/payment/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function markInvoiceAsPayed(data) {

    return baseAxios.post('accounting/invoices/payed/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getInvoicesForPaymentById(data) {

    return baseAxios.get('accounting/invoices/payment/id/',{params:{id:data}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getInvoicesForOrder(data) {

    return baseAxios.get('accounting/invoices/order/',{params:{order_fk:data}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getInvoicesForNotFinishedOrders(data) {

    return baseAxios.get('accounting/invoices/notfinished/',{params:{debitorcontact_fk:data.debitorcontact_fk,order_fk:data.order_fk}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function finishOrder(data) {

    return baseAxios.put('accounting/Order/finish/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function addRemoveInvoiceToOrder(data) {

    return baseAxios.post('accounting/orders/invoice/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function recalcOrderDiscount(data) {

    return baseAxios.post('accounting/order/RecalcDiscount/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function insertDiscount(data) {

    return baseAxios.post('discounts/OrderDiscount/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function updateDiscount(data) {

    return baseAxios.put('discounts/OrderDiscount/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteDiscount(id) {

    return baseAxios.delete('discounts/OrderDiscount/',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function deleteInvoice(id){
    return baseAxios.delete('accounting/invoice/',{params:{id:id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function stornoInvoice(data) {

    return baseAxios.put('accounting/invoice/storno',data )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getOrder(id) {

    return baseAxios.get('accounting/order/',{params: {id: id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getOffersByOrder(id) {

    return baseAxios.get('accounting/offers/order/',{params: {orderfk: id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getOfferForPartnerOrder(id) {

    return baseAxios.get('accounting/offer/partner/',{params: {id: id}})
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getOrderStats() {

    return baseAxios.get('accounting/order/stats/')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function sendDocumentMail(data) {

    return baseAxios.post('accounting/documentmail/',data)
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getOfferStats() {

    return baseAxios.get('accounting/offer/stats/')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
function getInvoiceStats() {

    return baseAxios.get('accounting/invoice/stats/')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}
