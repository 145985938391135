import { router } from '../router';
import {baseAxios} from '../helper/axiosHelper';

import LocalStorageService from './localstorage.service';

const localStorageService = LocalStorageService.getService();

export const userService = {
    login,
    logout,
    getUserMinData,
    getUserFullData,
    updateUserFullData,
    getUserOverviewData,
    getUserOverviewDataSingle,
    blockUser,
    unblockUser,
    newUser,
    deleteUser,
    changePassword,
    getUserRoles,
    newPartner,
    hello
};

function login(username, password, otp) {
    /*const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };*/

    //return fetch(`http://localhost:20000/api/auth/login/`, requestOptions)

    return baseAxios.post('auth/login/',{username: username,password:password,otp:otp}  )
        .then(handleResponse)
        .then(authtokens => {
            // login successful if there's a jwt token in the response
            if (authtokens.authToken) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorageService.setToken(authtokens);
            }

            return authtokens;
        });
}

function logout() {
    
    return baseAxios.delete('auth/')
    .then((response) =>
    {
        localStorageService.clearToken();
        return response;
    })
    .catch(function (error) {
        return error.response;
    });    
}

function hello() {
    
    return baseAxios.get('auth/test/')
    .then((response) =>
    {
        alert(response.data);
        return response;
    })
    .catch(function (error) {
        alert("ERROR! "+JSON.stringify(error));
        return error.response;
    });    
}

function changePassword(password_old, password_new) {

    return baseAxios.put('auth/password/',{oldPassword: password_old,newPassword:password_new}  )
    .then((response) =>
    {
        localStorageService.clrResetPwd();
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function deleteUser(userguid) {

    return baseAxios.delete('users/User/',{params:{userGuid: userguid}}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function blockUser(userguid) {

    return baseAxios.put('auth/block/',{userGuid: userguid,blocked:true} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function unblockUser(userguid) {

    return baseAxios.put('auth/block/',{userGuid: userguid,blocked:false}  )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getUserFullData(guid) {

    return baseAxios.get('users/UserFullData/',{params: {guid: guid}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getUserRoles() {

    return baseAxios.get('users/UserRoles/' )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function updateUserFullData(userdata) {
    
    return baseAxios.put('users/UserFullData/',userdata )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}


function newUser(newUserRequest) {
    
    return baseAxios.post('users/User/',newUserRequest )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getUserOverviewDataSingle(userguid) {

    return baseAxios.get('users/UserOverviewDataSingle/',{params: {guid: userguid}} )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getUserMinData() {
    
    return baseAxios.get('users/UserMinData/')
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function getUserOverviewData() {
    
    return baseAxios.get('users/UserOverviewData/')
    .then(response =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}

function handleResponse(response) {

        const data = response.data;
        if (response.status != 200) {
            localStorageService.clearToken();
            router.push('/login');
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
 
}

function newPartner(requ) {
    
    return baseAxios.post('users/Partner/',requ )
    .then((response) =>
    {
        return response;
    })
    .catch(function (error) {
        return error.response;
    });
}