
import { accountingService } from '../services';
import baseMethods from './base';

const initialState = {  offers: {data: null,status:{ loading: false,ready: false }},
                        offermembers: {data: null,status:{ loading: false,ready: false }},
                        orders: {data: [],status:{ loading: false,ready: false }},
                        invoices: {data: null,status:{ loading: false,ready: false }},
                        tempInvoices: {data: null,status:{ loading: false,ready: false }},
                        invoicesForOrder: {data: null,status:{ loading: false,ready: false }},
                        invoicesForNotFinishedOrders: {data: null,status:{ loading: false,ready: false }},
                        offer: {data: null,status:{ loading: false,ready: false }},
                        orderstats: {data: null,status:{ loading: false,ready: false }},
                        offerstats: {data: null,status:{ loading: false,ready: false }},
                        invoicestats: {data: null,status:{ loading: false,ready: false }}};

export const accounting = {
    namespaced: true,
    state: initialState,
    actions: {
        getOffers({ commit }, data )
        {
            commit('getOffersRequest' );
            return new Promise((resolve, reject) => {

                accountingService.getOffers(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getOffersSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getOffersFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getOrderStats({ commit } )
        {
            commit('getOrderStatsRequest' );
            return new Promise((resolve, reject) => {

                accountingService.getOrderStats().then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getOrderStatsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getOrderStatsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getOfferStats({ commit } )
        {
            commit('getOfferStatsRequest' );
            return new Promise((resolve, reject) => {

                accountingService.getOfferStats().then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getOfferStatsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getOfferStatsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getInvoiceStats({ commit } )
        {
            commit('getInvoiceStatsRequest' );
            return new Promise((resolve, reject) => {

                accountingService.getInvoiceStats().then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getInvoiceStatsSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getInvoiceStatsFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        
        // eslint-disable-next-line no-unused-vars
        confirmOffer({ commit },data )
        {
            return new Promise((resolve, reject) => {

                accountingService.confirmOffer(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        
        // eslint-disable-next-line no-unused-vars
        sendDocumentMail({ commit },data )
        {
            return new Promise((resolve, reject) => {

                accountingService.sendDocumentMail(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },

        getOffersByOrder({ commit }, data )
        {
            commit('getOffersRequest' );
            return new Promise((resolve, reject) => {

                accountingService.getOffersByOrder(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getOffersSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getOffersFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        
        getOffer({ commit }, id )
        {
            commit('getOfferRequest' );
            return new Promise((resolve, reject) => {

                accountingService.getOffer(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getOfferSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getOfferFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getOfferForPartnerOrder({ commit }, id )
        {
            commit('getOfferRequest' );
            return new Promise((resolve, reject) => {

                accountingService.getOfferForPartnerOrder(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getOfferSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getOfferFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        
        lockOffer({ commit }, data )
        {
            commit('lockOfferRequest' );
            return new Promise((resolve, reject) => {

                accountingService.lockOffer(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('lockOfferSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('lockOfferFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },     
        isOfferLocked({ commit }, id )
        {
            commit('isOfferLockedRequest' );
            return new Promise((resolve, reject) => {

                accountingService.isOfferLocked(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('isOfferLockedSuccess',response.data);
                            var resp = {isLocked:false,username:""};

                            if(response.data.txt1.length > 0)
                            {
                                resp.isLocked = true;
                                resp.username = response.data.txt2;
                                
                            }
                            resolve(resp);
                        }
                        else
                        {
                            commit('isOfferLockedFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },           
        getOfferMembers({ commit }, offer_fk )
        {
            commit('getOfferMembersRequest' );
            return new Promise((resolve, reject) => {

                accountingService.getOfferMembers(offer_fk).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getOfferMembersSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getOfferMembersFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },        
        saveOffer({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertOfferRequest' );
                    accountingService.insertOffer(data).then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertOfferSuccess',response.data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertOfferFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateOfferRequest' );
                    accountingService.updateOffer(data).then(
                        response => {
                            
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateOfferSuccess',response.data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateOfferFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
        
        saveOfferMemberAmount({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                commit('updateOfferMemberRequest' );
                accountingService.updateOfferMemberAmount(data).then(
                response => {
                    
                    if(baseMethods.IsSuccess(response))
                    {
                        commit('updateOfferMemberSuccess',data);
                        resolve(response.data);
                    }
                    else
                    {
                        commit('updateOfferMemberFailure');
                        reject(response.statusText);
                    }
                }
                );
            });
        },
        reorderOfferMember({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                commit('reorderOfferMemberRequest' );
                accountingService.reorderOfferMembers(data).then(
                response => {
                    
                    if(baseMethods.IsSuccess(response))
                    {
                        commit('reorderOfferMemberSuccess',data);
                        resolve(response.data);
                    }
                    else
                    {
                        commit('reorderOfferMemberFailure');
                        reject(response.statusText);
                    }
                }
                );
            });
        },

        

        saveOfferMember({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    commit('insertOfferMemberRequest' );
                    accountingService.insertOfferMember(data).then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('insertOfferMemberSuccess',response.data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('insertOfferMemberFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    commit('updateOfferMemberRequest' );
                    accountingService.updateOfferMember(data).then(
                        response => {
                            
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('updateOfferMemberSuccess',data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('updateOfferMemberFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
        insertOfferMembers({ commit }, data)
        {
            commit('insertOfferMembersRequest' );
            return new Promise((resolve, reject) => {
                
                accountingService.insertOfferMembers(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('insertOfferMembersSuccess',data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('insertOfferMembersFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteOfferMember({ commit }, id)
        {
            commit('deleteOfferMemberRequest' );
            return new Promise((resolve, reject) => {
                
                accountingService.deleteOfferMember(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteOfferMemberSuccess',id);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteOfferMemberFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        deleteOffer({ commit }, id)
        {
            commit('deleteOfferRequest' );
            return new Promise((resolve, reject) => {
                
                accountingService.deleteOffer(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            if(response.data.success)
                            {
                                commit('deleteOfferSuccess',id);
                            }
                            else
                            {
                                commit('deleteOfferSuccess',-1);// ready = true setzen
                            }
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteOfferFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        // eslint-disable-next-line no-unused-vars
        createOrderForOffer({ commit }, id)
        {
            return new Promise((resolve, reject) => {
                
                accountingService.createOrderForOffer(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        // eslint-disable-next-line no-unused-vars
        recalcOrderDiscount({ commit }, id)
        {
            return new Promise((resolve, reject) => {
                
                accountingService.recalcOrderDiscount(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        
        getOrders({ commit }, data )
        {
            commit('getOrdersRequest' );
            return new Promise((resolve, reject) => {

                if(data.debitorcontact_fk > 0)
                {
                    accountingService.getOrders(data).then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('getOrdersSuccess',response.data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('getOrdersFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    accountingService.getOrdersForUser().then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('getOrdersSuccess',response.data);
                                resolve(response.data);
                            }
                            else
                            {
                                commit('getOrdersFailure');
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
        // eslint-disable-next-line no-unused-vars
        createInvoiceForOrder({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                accountingService.createInvoiceForOrder(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getInvoices({ commit }, data )
        {
            commit('getInvoicesRequest' );
            return new Promise((resolve, reject) => {

                accountingService.getInvoices(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getInvoicesSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getInvoicesFailure');
                            reject(response.statusText);
                        }
                    }
                );
            })
        },
        deleteInvoice({ commit }, data )
        {
            commit('deleteInvoiceRequest' );
            return new Promise((resolve, reject) => {

                accountingService.deleteInvoice(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteInvoiceSuccess',response.data.id);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('deleteInvoiceFailure');
                            reject(response.statusText);
                        }
                    }
                );
            })
        },
        stornoInvoice({ commit }, data )
        {
            commit('stornoInvoiceRequest' );
            return new Promise((resolve, reject) => {

                accountingService.stornoInvoice(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('stornoInvoiceSuccess');
                            resolve(response.data);
                        }
                        else
                        {
                            commit('stornoInvoiceFailure');
                            reject(response.statusText);
                        }
                    }
                );
            })
        },
        // eslint-disable-next-line no-unused-vars
        getInvoicesForPayment({ commit }, data)
        {
            commit('getInvoicesForPaymentRequest' );
            return new Promise((resolve, reject) => {
                
                accountingService.getInvoicesForPayment(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getInvoicesForPaymentSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getInvoicesForPaymentFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },

        // eslint-disable-next-line no-unused-vars
        markInvoiceAsPayed({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                accountingService.markInvoiceAsPayed(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );
            })
        },
        getInvoicesForPaymentById({ commit }, id)
        {
            commit('getInvoicesForPaymentRequest' );
            return new Promise((resolve, reject) => {
                
                accountingService.getInvoicesForPaymentById(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getInvoicesForPaymentSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getInvoicesForPaymentFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getInvoicesForOrder({ commit }, data )
        {
            commit('getInvoicesForOrderRequest' );
            return new Promise((resolve, reject) => {

                accountingService.getInvoicesForOrder(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getInvoicesForOrderSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getInvoicesForOrderFailure');
                            reject(response.statusText);
                        }
                    }
                );
            })
        },
        getInvoicesForNotFinishedOrders({ commit }, data )
        {
            commit('getInvoicesForNotFinishedOrdersRequest' );
            return new Promise((resolve, reject) => {

                accountingService.getInvoicesForNotFinishedOrders(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getInvoicesForNotFinishedOrdersSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getInvoicesForNotFinishedOrdersFailure');
                            reject(response.statusText);
                        }
                    }
                );
            })
        },
        finishOrder({ commit }, data )
        {
            commit('finishOrderRequest' );
            return new Promise((resolve, reject) => {

                accountingService.finishOrder(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('finishOrderSuccess',data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('finishOrderFailure');
                            reject(response.statusText);
                        }
                    }
                );
            })
        },
        // eslint-disable-next-line no-unused-vars
        addRemoveInvoiceToOrder({ commit }, data )
        {
            return new Promise((resolve, reject) => {

                accountingService.addRemoveInvoiceToOrder(data).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );
            })
        },
        SaveOrderDiscount({ commit }, data)
        {
            return new Promise((resolve, reject) => {
                
                if(data.id <= 0)
                {
                    accountingService.insertDiscount(data).then(
                        response => {

                            if(baseMethods.IsSuccess(response))
                            {
                                commit('InsertDiscountSuccess',response.data);
                                resolve(response.data);
                            }
                            else
                            {
                                reject(response.statusText);
                            }
                        }
                    );
                }
                else
                {
                    accountingService.updateDiscount(data).then(
                        response => {
                            
                            if(baseMethods.IsSuccess(response))
                            {
                                commit('UpdateDiscountSuccess',data);
                                resolve(response.data);
                            }
                            else
                            {
                                reject(response.statusText);
                            }
                        }
                    );
                }
            })
        },
        deleteOrderDiscount({ commit }, id)
        {
            return new Promise((resolve, reject) => {
                
                accountingService.deleteDiscount(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('deleteOrderDiscountSuccess',id);
                            resolve(response.data);
                        }
                        else
                        {
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
        getOrder({ commit }, id )
        {
            commit('getOrderRequest' );
            return new Promise((resolve, reject) => {

                accountingService.getOrder(id).then(
                    response => {

                        if(baseMethods.IsSuccess(response))
                        {
                            commit('getOrderSuccess',response.data);
                            resolve(response.data);
                        }
                        else
                        {
                            commit('getOrderFailure');
                            reject(response.statusText);
                        }
                    }
                );

            })
        },
    },
    mutations: {
        getOffersRequest(state)
        {
            state.offers = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getOffersSuccess(state, data)
        {
            state.offers = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getOffersFailure(state)
        {
            state.offers = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getOfferRequest(state)
        {
            state.offer = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getOfferSuccess(state, data)
        {
            state.offer = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getOfferFailure(state)
        {
            state.offer = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getOfferMembersRequest(state)
        {
            state.offermembers = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getOfferMembersSuccess(state, data)
        {
            state.offermembers = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getOfferMembersFailure(state)
        {
            state.offermembers = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        insertOfferRequest(state)
        {
            state.offers.status = { loading: true,ready: false };
        },
        insertOfferSuccess(state)
        {
            state.offers.status = { loading: false,ready: true };
        },
        insertOffersFailure(state)
        {
            state.offers.status = { loading: false,ready: false };
        },
        updateOfferRequest(state)
        {
            state.offers.status = { loading: true,ready: false };
        },
        updateOfferSuccess(state)
        {
            state.offers.status = { loading: false,ready: true };
        },
        updateOfferFailure(state)
        {
            state.offers.status = { loading: false,ready: false };
        },
        reorderOfferMemberRequest(state)
        {
            state.offermembers.status = { loading: true,ready: false };
        },
        reorderOfferMemberSuccess(state)
        {
            state.offermembers.status = { loading: false,ready: true };
        },
        reorderOfferMemberFailure(state)
        {
            state.offermembers.status = { loading: false,ready: false };
        },
        insertOfferMemberRequest(state)
        {
            state.offermembers.status = { loading: true,ready: false };
        },
        insertOfferMemberSuccess(state,data)
        {
            state.offermembers.status = { loading: false,ready: true };

            state.offermembers.data.push(data);
        },
        insertOfferMemberFailure(state)
        {
            state.offermembers.status = { loading: false,ready: false };
        },
        
        insertOfferMembersRequest(state)
        {
            state.offermembers.status = { loading: true,ready: false };
        },
        insertOfferMembersSuccess(state,data)
        {
            state.offermembers.status = { loading: false,ready: true };
            state.offermembers.data = data;
        },
        insertOfferMembersFailure(state)
        {
            state.offermembers.status = { loading: false,ready: false };
        },

        updateOfferMemberRequest(state)
        {
            state.offermembers.status = { loading: true,ready: false };
        },
        updateOfferMemberSuccess(state,data)
        {
            state.offermembers.status = { loading: false,ready: true };

            state.offermembers.data.forEach(member=>{

                if(member.id == data.id)
                {
                    // Daten direkt aktualisieren, aber nicht alle
                    // da es Werte gibt wie language_fk die sich nie ändern
                    member.product_fk= data.product_fk;
                    member.product_bez= data.product_bez;
                    member.article_nr= data.article_nr;
                    member.ral_fk= data.ral_fk;
                    member.netto= data.netto;
                    member.brutto= data.brutto;
                    member.text= data.text;
                    member.text_only= data.text_only;
                    member.surface_txt= data.surface_txt;
                    member.amount = data.amount;
                    member.sum_netto= data.sum_netto;
                    member.sum_brutto= data.sum_brutto;
                    member.orderNr = data.orderNr;
                }
            });
        },
        updateOfferMemberFailure(state)
        {
            state.offermembers.status = { loading: false,ready: false };
        },
        deleteOfferMemberRequest(state)
        {
            state.offermembers.status = { loading: true,ready: false };
        },
        deleteOfferMemberSuccess(state,id)
        {
            state.offermembers.status = { loading: false,ready: true };

            for(var i = 0; i < state.offermembers.data.length; i++)
            {
                if(state.offermembers.data[i].id == id)
                {
                    state.offermembers.data.splice(i, 1);
                    break;
                }
            }
        },
        deleteOfferMemberFailure(state)
        {
            state.offermembers.status = { loading: false,ready: false };
        },
        deleteOfferRequest(state)
        {
            state.offers.status = { loading: true,ready: false };
        },
        deleteOfferSuccess(state,id)
        {
            state.offers.status = { loading: false,ready: true };

            for(var i = 0; i < state.offers.data.length; i++)
            {
                if(state.offers.data[i].id == id)
                {
                    state.offers.data.splice(i, 1);
                    break;
                }
            }
        },
        deleteOfferFailure(state)
        {
            state.offers.status = { loading: false,ready: false };
        },
        lockOfferRequest(state)
        {
            state.offers.status = { loading: true,ready: false };
        },
        lockOfferSuccess(state,data)
        {
            state.offers.status = { loading: false,ready: false };
            if(state.offers.data != null)
            {
                for(var i = 0; i < state.offers.data.length; i++)
                {
                    if(state.offers.data[i].id == data.id)
                    {
                        state.offers.data[i].lockedByUserGuid = "";
                        state.offers.data[i].lockedByUsername = "";
                        if(data.txt1.length > 0)
                        {
                            state.offers.data[i].lockedByUserGuid = data.txt1;
                            state.offers.data[i].lockedByUsername = data.txt2;
                        }
                        break;
                    }
                }
            }
            
        },
        lockOfferFailure(state)
        {
            state.offers.status = { loading: false,ready: false };
        },
        isOfferLockedRequest(state)
        {
            state.offers.status = { loading: true,ready: false };
        },
        isOfferLockedSuccess(state,data)
        {
            state.offers.status = { loading: false,ready: false };
            for(var i = 0; i < state.offers.data.length; i++)
            {
                if(state.offers.data[i].id == data.id)
                {
                    state.offers.data[i].lockedByUserGuid = "";
                    state.offers.data[i].lockedByUsername = "";
                    if(data.txt1.length > 0)
                    {
                        state.offers.data[i].lockedByUserGuid = data.txt1;
                        state.offers.data[i].lockedByUsername = data.txt2;
                    }
                    break;
                }
            }
        },
        isOfferLockedFailure(state)
        {
            state.offers.status = { loading: false,ready: false };
        },
        getOrdersRequest(state)
        {
            state.orders = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getOrdersSuccess(state, data)
        {
            state.orders = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getOrdersFailure(state)
        {
            state.orders = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getInvoicesRequest(state)
        {
            state.invoices = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getInvoicesSuccess(state, data)
        {
            state.invoices = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getInvoicesFailure(state)
        {
            state.invoices = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        deleteInvoiceRequest(state)
        {
            state.invoices.status = { loading: true,ready: false };            
        },
        deleteInvoiceSuccess(state,id)
        {
            state.invoices.status = { loading: false,ready: true };            
            for(var i = 0; i < state.invoices.data.length; i++)
            {
                if(state.invoices.data[i].id == id)
                {
                    state.invoices.data.splice(i,1);
                    break;
                }
            }
        },
        deleteInvoiceFailure(state)
        {
            state.invoices.status = { loading: false,ready: false };            
        },
        stornoInvoiceRequest(state)
        {
            state.invoices.status = { loading: true,ready: false };            
        },
        stornoInvoiceSuccess(state)
        {
            state.invoices.status = { loading: false,ready: true };            
        },
        stornoInvoiceFailure(state)
        {
            state.invoices.status = { loading: false,ready: false };            
        },
        
        getInvoicesForPaymentRequest(state)
        {
            state.tempInvoices = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getInvoicesForPaymentSuccess(state, data)
        {
            state.tempInvoices = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getInvoicesForPaymentFailure(state)
        {
            state.tempInvoices = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        
        getInvoicesForOrderRequest(state)
        {
            state.invoicesForOrder = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getInvoicesForOrderSuccess(state, data)
        {
            state.invoicesForOrder = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getInvoicesForOrderFailure(state)
        {
            state.invoicesForOrder = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getInvoicesForNotFinishedOrdersRequest(state)
        {
            state.invoicesForNotFinishedOrders = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getInvoicesForNotFinishedOrdersSuccess(state, data)
        {
            state.invoicesForNotFinishedOrders = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getInvoicesForNotFinishedOrdersFailure(state)
        {
            state.invoicesForNotFinishedOrders = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        finishOrderRequest(state)
        {
            state.orders.status = { loading: true,ready: false };
            
        },
        finishOrderSuccess(state, data)
        {
            for(var i=0; i < state.orders.data.length; i++)
            {
                if(state.orders.data[i].id == data.orderFk)
                {
                    state.orders.data[i].state = data.state;
                    break;
                }
            }

            state.orders.status = { loading: false,ready: true };
        },
        finishOrderFailure(state)
        {
            state.orders.status = { loading: false,ready: false }
        },

        InsertDiscountSuccess(state,data)
        {
            state.orders.data.forEach(order => {
                if(order.id == data.order_fk)
                {
                    order.discountmember.push(data);
                }
            })
        },

        UpdateDiscountSuccess(state,data)
        {
            state.orders.data.forEach(order => {
                if(order.id == data.order_fk)
                {
                    for(var i = 0; i < order.discountmember.length;i++)
                    {
                        if(order.discountmember[i].id == data.id)
                        {
                            order.discountmember[i] = JSON.parse(JSON.stringify(data));
                            break;
                        }
                    }
                }
            })
        },
        DeleteDiscountSuccess(state,id)
        {
            state.orders.data.forEach(order => {
                for(var i = 0; i < order.discountmember.length;i++)
                {
                    if(order.discountmember[i].id == id)
                    {
                        order.discountmember.splice(i,1);
                        break;
                    }
                }
            })
        },

        getOrderRequest(state)
        {
            state.orders.status = { loading: true,ready: false };
        },
        getOrderSuccess(state, data)
        {
            state.orders.status = { loading: false,ready: true };
            var found = false;
            for(var i = 0; i < state.orders.data.length; i++)
            {
                if(state.orders.data[i].id == data.id)
                {
                    found = true;
                    state.orders.data[i] = JSON.parse(JSON.stringify(data));
                    break;
                }
            }
            if(found == false)
            {
                state.orders.data.push(data);
            }
        },
        getOrderFailure(state)
        {
            state.orders.status = { loading: false,ready: true };
        },
        getOrderStatsRequest(state)
        {
            state.orderstats = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getOrderStatsSuccess(state, data)
        {
            state.orderstats = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getOrderStatsFailure(state)
        {
            state.orderstats = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getOfferStatsRequest(state)
        {
            state.offerstats = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getOfferStatsSuccess(state, data)
        {
            state.offerstats = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getOfferStatsFailure(state)
        {
            state.offerstats = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
        getInvoiceStatsRequest(state)
        {
            state.invoicestats = {
                data: null,
                status:{ loading: true,ready: false }
            };
        },
        getInvoiceStatsSuccess(state, data)
        {
            state.invoicestats = {
                data: data,
                status:{ loading: false,ready: true }
            };
        },
        getInvoiceStatsFailure(state)
        {
            state.invoicestats = {
                data: null,
                status:{ loading: false,ready: false }
            };
        },
    }
}
